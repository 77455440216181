<template>
  <div class="login-container">
    <el-form class="login-form" ref="loginFromRef" :model="loginForm" :rules="loginRules">
      <div class="title-container">
        <img src="../../layout/components/Sidebar/1.png" />
      </div>
      <div class="form-area">
        <el-form-item prop="email">
          <span class="svg-container">
            <svg-icon icon="user" />
          </span>
          <el-input placeholder="请输入用户名" name="email" type="text" v-model="loginForm.email" />
        </el-form-item>

        <el-form-item prop="password">
          <span class="svg-container">
            <svg-icon icon="password" />
          </span>
          <el-input placeholder="请输入密码" name="password" v-model="loginForm.password" :type="passwordType" />
          <span class="show-pwd">
            <span class="svg-container">
              <svg-icon :icon="passwordType === 'password' ? 'eye' : 'eye-open'" @click="onChangePwdType" />
            </span>
          </span>
        </el-form-item>
      </div>
      <!--      <div class="tips" v-html="$t('msg.login.desc')"></div>-->
    </el-form>
    <button class="btn" :loading="loading" @click="handleLogin">登录</button>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { validatePassword } from './rules'
import { useStore } from 'vuex'
import { gologin } from "@/api/sys";
import router from "@/router";
// 账号密码
const loginForm = ref({
  email: 'kjlm@unions9.com',
  password: 'kjlm@offical'
})
// 表单验证规则
const loginRules = ref({
  email: [
    {
      required: true,
      trigger: 'blur',
      message: '用户名不能为空'
    }
  ],
  password: [
    {
      required: true,
      trigger: 'blur',
      validator: validatePassword()
    }
  ]
})

// 处理密码框文本显示状态
const passwordType = ref('password')
const onChangePwdType = () => {
  if (passwordType.value === 'password') {
    passwordType.value = 'text'
  } else {
    passwordType.value = 'password'
  }
}

// 登录动作处理
const loading = ref(false)
const loginFromRef = ref(null)
const store = useStore()
// 点击登录
const handleLogin = () => {
  // 必填项校验
  loginFromRef.value.validate((valid) => {
    if (!valid) return

    loading.value = true
    // store.dispatch('user/onLogin', {
    //   email: loginForm.value.email,
    //   password: loginForm.value.password
    // })
    gologin({
      email: loginForm.value.email,
      password: loginForm.value.password
    }).then(res => {
      console.log(res)
      store.commit('user/setToken', res.access_token);
      console.log(store.state.user.token)
      router.push('/profile')
      // router.push('/')
    }).catch(err => {
      console.log(err)
    })
  })
}
</script>

<style lang="scss" scoped>
$bg: white;
$dark_gray: #889aa4;
$light_gray: #eee;
$cursor: #fff;

.login-container {
  height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;


  .login-form {


    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;

    :deep(.el-form-item) {
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 5px;
      color: #004984;
    }

    :deep(.el-input) {
      display: inline-block;
      height: 47px;
      width: 85%;
      --el-input-focus-border-color: none;
      --el-input-border-color: none;
      --el-input-hover-border-color: none;

      input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        color: $dark_gray;
        height: 47px;
        caret-color: $cursor;
      }
    }
  }

  .tips {
    font-size: 16px;
    color: white;
    line-height: 24px;
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: #004984;
    vertical-align: middle;
    display: inline-block;
  }

  .title-container {
    position: relative;
    margin-bottom: 30px;

    img {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      margin-left: calc(50% - 100px);
    }

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  :deep(.lang-select) {
    position: absolute;
    top: 10px;
    right: 0;
    background-color: #fff;
    font-size: 22px;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
  }

  .form-area {
    background-color: white;
    border-radius: 10px;
  }
}

.btn {
  color: white;
  background-color: #004984;
  border: none;
  padding: 10px 0;
  width: 520px;
  margin-left: calc(50vw - 260px);
  border-radius: 10px;
  margin-top: 30px;
}
</style>
